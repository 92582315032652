import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import headerIcon from "../images/app/headerIcon.svg"
import iphone from "../images/app/iphone.svg"
import crossplatform from "../images/app/crossplatform.svg"
import android from "../images/app/android.svg"

export default function MobileAppDevelopment() {
  return (
    <Layout>
      <SEO title="App Developoment" />
      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.appDark}
        bgColor={props => props.theme.procolors.appLight}
        title="Make your business accessible."
        catchy="Mobile Application Development"
        tagline="70% of the world uses smartphones. It’s time to catch up with a flawless app that puts your business right in the hands of your customers."
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="App development"
          details="Providing a mobile app to your customers makes your business more accessible and can help to generate additional revenue. eDigitalHub offers mobile app development which can be an effective tool for helping your business to thrive."
          color={props => props.theme.procolors.appDark}
        />
        <ProductDetails
          to="/contact/"
          title="The app services we offer include:"
          color={props => props.theme.procolors.appDark}
        >
          <CardLeft
            alt="IPhone app development"
            title="IPhone app development"
            detail="With our iPhone app development services, your business will have the perfect iPhone app to help you connect with your customers."
            src={iphone}
          />
          <CardRight
            alt="Android app development"
            title="Android app development"
            detail="Our Android app development services will allow you to have a user-friendly app that your customers can easily access on their Android phones."
            src={android}
          />
          <CardLeft
            alt="Cross platform app development"
            title="Cross platform app development"
            detail="Create an app that is compatible with both iPhones and Androids, reaching an even larger customer base and increasing your growth in the long run."
            src={crossplatform}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.appLight}
          title="Why Do You Need an App?"
          details="An app will make your business stand out from the crowd. By utilising our app development service, your business will be able to promote your products and services more easily and remain more accessible to your customers."
          details2="By frequently updating your company’s app, we can also generate more customer interest in your products and services. Above all else, an official mobile app will contribute to brand awareness and increase the visibility of your company among your audience."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
